/* this file is transformed by vux-loader */
/* eslint-disable */
import axios from "axios";
import router from '@/router/index';
export default {
  data() {
    return {
      osUser: {},
      mainDepId: "",
      mainDepName: "",
      canGroupIds: "",
      canGroupNames: "",
      canDepIds: "",
      canDepNames: "",
      fields: ['mainDepId', 'mainDepName', 'canGroupIds', 'canGroupNames', 'canDepIds', 'canDepNames'],
      navbar_bgcolor: "#409eff",
      navbar_font_color: "#FFF"
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      var url = _baseUrl + '/mobile/oa/personalInfo/getUser.do';
      // this.$ajax.get(url).then(response => {
      axios.get(url).then(response => {
        this.osUser = response.data.osUser;
        for (let i = 0, j = this.fields.length; i < j; i++) {
          var fieldName = this.fields[i];
          this[fieldName] = response.data[fieldName];
        }
        if (this.osUser.entryTime) {
          this.osUser.entryTime = this.$moment(this.osUser.entryTime).format("YYYY-MM-DD");
        }
        if (this.osUser.quitTime) {
          this.osUser.quitTime = this.$moment(this.osUser.quitTime).format("YYYY-MM-DD");
        }
      });
    },
    editInfo() {
      var params = {};
      for (let key in this.osUser) {
        params[key] = this.osUser[key];
      }
      for (let i = 0, j = this.fields.length; i < j; i++) {
        var fieldName = this.fields[i];
        params[fieldName] = this[fieldName];
      }
      var url = _baseUrl + "/mobile/oa/personalInfo/editUser.do";
      this.$ajaxJson({
        url: url,
        method: 'post',
        params: params
      }).then(response => {
        var rtn = response.data;
        if (rtn.success) {
          this.$dialog.toast({
            mes: rtn.message,
            timeout: 1500,
            icon: 'success'
          });
        }
      }).catch(error => {
        console.log(error);
      });
    },
    cancel() {
      router.go(-1);
    }
  }
};